import React from "react";
import { indexHolder } from "./index.module.scss";

export default function IndexPage() {
  return (
    <article className={indexHolder}>
      <h2>
        Girls Girls Girls is collective of female artists who celebrate and empower womxn in the music
        industry and campaign against female genital cutting
      </h2>
    </article>
  );
}
